import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  apiUrl = 'https://lotonlynodejs.yuzudigital.com/';
  // apiUrl = 'http://localhost:8080/';

  constructor(
    private http: HttpClient
  ) { }

  // getAllLot(param: any) {
  //   return new Promise((resolve, reject) => {
  //     const header = new HttpHeaders();
  //     header.append('Content-type', 'json/data; charset=utf-8');

  //     this.http.post(this.apiUrl + 'getalllot', param, { headers: header })
  //       .subscribe(res => {

  //         // this.setUserLogin(res);
  //         resolve(res);
  //       }, (err) => {
  //         reject(err);
  //       });
  //   });
  // }
/*จัดการลอตเตอรี่*/ 
  getAllLot(param: any): Observable<any> {
    const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');
    return this.http.post(this.apiUrl + 'getalllot', param, { headers: header });
  }
  
  register_lot(param: any): Observable<any> {
    const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');
    return this.http.post(this.apiUrl + 'register_lot', param, { headers: header });
  }

  update_lot(param: any): Observable<any> {
    const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');
    return this.http.post(this.apiUrl + 'update_lot', param, { headers: header });
  }

  delete_lot(param: any): Observable<any> {
    const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');
    return this.http.post(this.apiUrl + 'delete_lot', param, { headers: header });
  }
/*รายการสั่งซื้อ*/ 
  listTransaction(): Observable<any> {
    const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');
    return this.http.post(this.apiUrl + 'listTransaction', { headers: header });
  }

  updateTransaction(param: any): Observable<any> {
    const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');
    return this.http.post(this.apiUrl + 'updateTransaction', param, { headers: header });
  }

  updateDelivery(param: any): Observable<any> {
    const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');
    return this.http.post(this.apiUrl + 'updateDelivery', param, { headers: header });
  }
  /*ขึ้นเงินรางวัล*/ 
  updateRewardTransaction(param: any): Observable<any> {
    const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');
    return this.http.post(this.apiUrl + 'updateRewardTransaction', param, { headers: header });
  }

  updateConfirmTransferTransaction(param: any): Observable<any> {
    const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');
    return this.http.post(this.apiUrl + 'updateConfirmTransferTransaction', param, { headers: header });
  }
  /*จัดการผลรางวัล*/ 
  allReward(param: any): Observable<any> {
    const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');
    return this.http.post(this.apiUrl + 'allReward', param, { headers: header });
  }

  checkReward(param: any): Observable<any> {
    const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');
    return this.http.post(this.apiUrl + 'checkReward', param, { headers: header });
  }

  /*สมาชิก*/
  listAllUser(): Observable<any> {
    const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');
    return this.http.get(this.apiUrl + 'listAllUser', { headers: header });
  }

  /*ข่าวลอตเตอรี่*/
  getNews(): Observable<any> {
    const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');
    return this.http.get(this.apiUrl + 'getNews', { headers: header });
  }
  createNews(param: any): Observable<any> {
    const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');
    return this.http.post(this.apiUrl + 'createNews', param, { headers: header });
  }
  editNews(param: any): Observable<any> {
    const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');
    return this.http.post(this.apiUrl + 'editNews', param, { headers: header });
  }
  setActiveNews(param: any): Observable<any> {
    const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');
    return this.http.post(this.apiUrl + 'setActiveNews', param, { headers: header });
  }

  /*รายงาน*/
  summaryTransaction(): Observable<any> {
    const header = new HttpHeaders();
      header.append('Content-type', 'json/data; charset=utf-8');
    return this.http.post(this.apiUrl + 'summaryTransaction', { headers: header });
  }
}
