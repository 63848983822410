import { Routes, RouterModule } from '@angular/router';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { AuthGuard } from './_guards/auth.guard';
import { RegisterComponent } from './register';
import { LoginComponent } from './login';
import { ChangelogComponent } from './changelog/changelog.component';
import { FullLayoutComponent } from './_layout/full-layout/full-layout.component';
import { LotteryCheckPublicComponent } from './content/lottery-public/lottery-check-public/lottery-check-public.component';

const appRoutes: Routes = [
  // Public layout
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: 'register', component: RegisterComponent },
      { path: 'login', component: LoginComponent },
      { path: 'lottery-check-public', component: LotteryCheckPublicComponent },
      { path: '', component: LoginComponent }
    ]
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      { path: 'error', loadChildren: '../app/content/full-pages/error/error.module#ErrorModule', canActivate: [AuthGuard] },
      {
        path: 'authentication', loadChildren: '../app/content/full-pages/authentication/authentication.module#AuthenticationModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'others', loadChildren: '../app/content/full-pages/others/others.module#OthersModule',
        canActivate: [AuthGuard]
      },
    ]
  },
  // Private layout
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      { path: 'logout', component: LoginComponent, canActivate: [AuthGuard] },
      { path: 'changelog', component: ChangelogComponent, canActivate: [AuthGuard] },
      { path: 'dashboard', loadChildren: '../app/content/dashboard/dashboard.module#DashboardModule', canActivate: [AuthGuard] },
      {
        path: 'components', loadChildren: '../app/content/ngbbootstrap/components.module#ComponentsModule',
        canActivate: [AuthGuard]
      },
      { path: 'todos', loadChildren: '../app/content/applications/todos/todos.module#TodosModule', canActivate: [AuthGuard] },
      { path: 'chats', loadChildren: '../app/content/applications/chat/chats.module#ChatsModule', canActivate: [AuthGuard] },
      { path: 'email', loadChildren: '../app/content/applications/email/email.module#EmailModule', canActivate: [AuthGuard] },
      {
        path: 'calender', loadChildren: '../app/content/applications/calender/calender.module#CalenderModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'contacts', loadChildren: '../app/content/applications/contacts/contacts.module#ContactsModule',
        canActivate: [AuthGuard]
      },
      { path: 'chartjs', loadChildren: '../app/content/charts-maps/chartjs/chartjs.module#ChartjsModule', canActivate: [AuthGuard] },
      {
        path: 'form-elements', loadChildren: '../app/content/forms/form-elements/form-elements.module#FormElementsModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'form-layouts', loadChildren: '../app/content/forms/form-layouts/form-layouts.module#FormLayoutsModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'form-wizard', loadChildren: '../app/content/forms/form-wizard/form-wizard.module#FormWizardModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'form-repeater', loadChildren: '../app/content/forms/form-repeater/form-repeater.module#FormRepeaterModule',
        canActivate: [AuthGuard]
      },
      { path: 'content', loadChildren: '../app/content/ui-content/ui-content.module#UIContentModule', canActivate: [AuthGuard] },
      {
        path: 'ngchartist', loadChildren: '../app/content/charts-maps/ngchartist/ngchartist.module#NgchartistModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'boostraptables', loadChildren: '../app/content/table/boostraptables/boostraptables.module#BoostraptablesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'datatables', loadChildren: '../app/content/table/datatables/datatables.module#DatatablesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'datatablesext', loadChildren: '../app/content/table/datatablesext/datatablesext.module#DatatablesextModule',
        canActivate: [AuthGuard]
      },
      { path: 'icons', loadChildren: '../app/content/icons/icons.module#IconsModule', canActivate: [AuthGuard] },
      { path: 'cards', loadChildren: '../app/content/cards/cards.module#CardsModule', canActivate: [AuthGuard] },
      { path: 'maps', loadChildren: '../app/content/maps/maps.module#MapsModule', canActivate: [AuthGuard] },
      { path: 'invoice', loadChildren: '../app/content/pages/invoice/invoice.module#InvoiceModule', canActivate: [AuthGuard] },
      {
        path: 'timelines', loadChildren: '../app/content/pages/timelines/timelines.module#TimelinesModule',
        canActivate: [AuthGuard]
      },
      { path: 'user', loadChildren: '../app/content/pages/user/user.module#UserModule', canActivate: [AuthGuard] },
      { path: 'gallery', loadChildren: '../app/content/pages/gallery/gallery.module#GalleryModule', canActivate: [AuthGuard] },
      { path: 'news-feed', loadChildren: '../app/content/pages/news-feed/news-feed.module#NewsFeedModule', canActivate: [AuthGuard] },
      {
        path: 'social-feed', loadChildren: '../app/content/pages/social-feed/social-feed.module#SocialFeedModule',
        canActivate: [AuthGuard]
      },
      { path: 'search', loadChildren: '../app/content/pages/search/search.module#SearchModule', canActivate: [AuthGuard] },
      {
        path: 'advanceCards', loadChildren: '../app/content/advance-cards/advance-cards.module#AdvanceCardsModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'extraComponents', loadChildren: '../app/content/extra-components/extra-components.module#ExtraComponentsModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'lottery', loadChildren: '../app/content/lottery/lottery.module#LotteryModule',
        canActivate: [AuthGuard]
      }
    ],
  },
  // otherwise redirect to home
  { path: '**', redirectTo: 'dashboard/sales' }
];

export const routing = RouterModule.forRoot(appRoutes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top' });
