import { Component, OnInit, ViewChild} from '@angular/core'; 
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { PerfectScrollbarComponent, PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { RestService } from 'src/app/services/rest.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lottery-check-public',
  templateUrl: './lottery-check-public.component.html',
  styleUrls: ['./lottery-check-public.component.css']
})
export class LotteryCheckPublicComponent implements OnInit {

  public configScroll: PerfectScrollbarConfigInterface = { suppressScrollY : false, wheelPropagation: false };
  public config: PerfectScrollbarConfigInterface = { suppressScrollY : true };
  @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: true }) directiveRef?: PerfectScrollbarDirective;

  options = {
    close: false,
    expand: false,
    minimize: false,
    reload: false
  };
  temp = [];
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

  constructor(private restApi: RestService,
    private formBuilder: FormBuilder,
    private modal: NgbModal,) {

  }
  data: any;
  checkResponse:any;
  row: any;
  public breadcrumb: any;
  temp2 = this.row;
  myParamAll: FormGroup;
  submittedAll = false;
  pressSubmittedAll = false;

  reward_1 = [];
  reward_2 = [];
  reward_3 = [];
  reward_4 = [];
  reward_5 = [];
  front_3 = [];
  last_3 = [];
  last_2 = [];
  side_1 = [];
  total_reward_1:any;
  total_reward_2:any;
  total_reward_3:any;
  total_reward_4:any;
  total_reward_5:any;
  total_front_3:any;
  total_last_3:any;
  total_last_2:any;
  total_side_1:any;

  responseModal = null;

  ngOnInit() {
    //this.breadcrumb = {
    //  'mainlabel': 'จัดการผลรางวัล'
    //};
    document.getElementsByTagName('body')[0].classList.remove('fixed-navbar');
    this.myParamAll = this.formBuilder.group({
      award_date_all: ['', Validators.required]
    });
    
  }

  ngAfterViewInit(){
    document.getElementsByClassName('card')[0].classList.add('card-fullscreen');
  }

  get fa() {
    return this.myParamAll.controls;
  }

  onAllReward(){
    this.submittedAll = true;

    if (this.myParamAll.invalid) { //2020-03-01
      return;
    }else{
      this.pressSubmittedAll = true;
      console.log(this.myParamAll.value.award_date_all);
      const param = {
        award_date: this.myParamAll.value.award_date_all.year+'-'+ 
        ("0"+this.myParamAll.value.award_date_all.month).slice(-2)+'-'+
        ("0"+this.myParamAll.value.award_date_all.day).slice(-2)
      };
      this.restApi.allReward(param).subscribe(Response => {
        this.submittedAll = false;
        this.pressSubmittedAll = false;
        this.data = Response;
        console.log(Response);
        this.getTabledata();
      });
    }
  }
  
  getTabledata() {
    this.reward_1 = this.data.reward_1;
    this.total_reward_1 = this.data.reward_1.length;
    this.reward_2 = this.data.reward_2;
    this.total_reward_2 = this.data.reward_2.length;
    this.reward_3 = this.data.reward_3;
    this.total_reward_3 = this.data.reward_3.length;
    this.reward_4 = this.data.reward_4;
    this.total_reward_4 = this.data.reward_4.length;
    this.reward_5 = this.data.reward_5;
    this.total_reward_5 = this.data.reward_5.length;
    this.front_3 = this.data.front_3;
    this.total_front_3 = this.data.front_3.length;
    this.last_3 = this.data.last_3;
    this.total_last_3 = this.data.last_3.length;
    this.last_2 = this.data.last_2;
    this.total_last_2 = this.data.last_2.length;
    this.side_1 = this.data.side_1;
    this.total_side_1 = this.data.side_1.length;
    console.log(this.data);
   }
}

